import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
// import Img from 'gatsby-image'
import SEO from "./../../components/seo"

// s4n
import FragmentPageDetails from '../../model/fragments/nodePage/page-details'
import { injectIntl } from "gatsby-plugin-intl"
import { useParseInlineImages } from '../../hooks/drupal/parseInlineImages'
import loadable from '@loadable/component'
// import ProductRelated from "./../../components/s4n/Shop/Product/related"
// import Subpages from "./../../components/s4n/Menu/subpages"
// import DrupalModuleParagraphsContainer from '../../components/drupal/module/paragraphs/container'



// @gpt
// import { GatsbyImage, getImage } from 'gatsby-plugin-image';
// import parse from 'html-react-parser';



const ComponentStateless = (props) => {
  const { intl, data } = props;
  const node = data.nodePage;
  // const t = intl.formatMessage;

  const ProductRelated = loadable(() => import(`./../../components/s4n/Shop/Product/related`));
  const Subpages = loadable(() => import(`./../../components/s4n/Menu/subpages`));
  const oReplacement = {
    [`[s4n_related_products]`]: <ProductRelated products={node.relationships && node.relationships.field_related_products ? node.relationships.field_related_products : []} />,
    [`[s4n_subpages]`]: <Subpages nid={node.drupal_internal__nid} exclude={[866]} displaySectionShortDescriptionForParentNodeId={[1054]} />, // exclude globally about-us/news :)
  }

  //let nodeBodyField = node.body ? node.body.value : null;
  // const nodeBodyField = useParseInlineImages(node.body ? node.body.value : '', intl, true, oReplacement) // You can use the react-html-parser in case you don't want to use dangerouslySetInnerHTML attribute
  const nodeBodyField = useParseInlineImages(node, intl, true, oReplacement); // You can use the react-html-parser in case you don't want to use dangerouslySetInnerHTML attribute
  
  // let nodeParagraphField = node.relationships && node.relationships.field_paragraph
  // let nodeParagraphField = useParseBodyInlineImages(node.relationships.field_paragraph)



  // @gpt
  // const { body } = node;
  // const options = {
  //   replace: ({ name, attribs }) => {
  //     if (name === 'img' && attribs.class && attribs.class.includes('gatsby-image')) {
  //       const image = getImage(attribs.src);
  //       if (image) {
  //         return <GatsbyImage image={image} alt={attribs.alt} />;
  //       }
  //     }
  //   },
  // };




  return (
    <Layout node={node}>
        <SEO 
          title={node.title} 
          keywords={node.field_keywords}
          nodeId={node.drupal_internal__nid}
          field_metatag={node.field_metatag}
        />

        <div className="container">
          <h1>{ node.title }</h1>
          {/* <small><em>{ node.created }</em></small> */}
        </div>

        {/* <div style={{ maxWidth: `900px`, marginBottom: `1.45rem`, width: `100%` }}>
          <Img fluid={ node.relationships.field_image.localFile.childImageSharp.fluid } />
        </div> */}

        { nodeBodyField }

        {/* <div dangerouslySetInnerHTML={{ __html: nodeBodyField }}></div> */}

        {/* <DrupalModuleParagraphsContainer>
          { nodeParagraphField }
        </DrupalModuleParagraphsContainer> */}

    </Layout>
  )
}

export default injectIntl(ComponentStateless)

// this query is Promie, we export it to let Gatsby fill variables from context from gatsby-node.js (id )
// and pass back to this file as props to below component
// @see: https://github.com/gatsbyjs/gatsby/tree/master/packages/gatsby-source-wordpress#query-with-acf-flexible-content
// @see: https://github.com/gatsbyjs/gatsby/tree/master/packages/gatsby-source-wordpress#query-with-acf-flexible-content
export const query = graphql`
    query(
      $id: String!,
      $drupal_id: String!,
      $langcode: String!
    ) {
    nodePage(
      id: {
        eq: $id
      },
      drupal_id: {
        eq: $drupal_id
      },
      langcode: {
        eq: $langcode
      }
    ) {
        ...FragmentPageDetails
    }
  }
`
